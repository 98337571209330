import React from "react";
import {NavBar, NavMenu} from "./Navigation";

function Tagline() {
  return (
    <div className="site-tagline-wrapper ">
      <div className="site-tagline">
        costume/prop design for theatre
      </div>
    </div>
  );
}

function Header() {
  return (
    <header id="header" className="site-header flex justify-between gap-x-4 mt-10 mr-14 ml-14">
      <div className="self-center flex flex-wrap gap-x-2">
        <div className="logo-wrapper">
          <div className="site-title">
            <a href="/">
              Marília Saito
            </a>
          </div>
        </div>
      </div>
      <NavBar />
      <NavMenu />
    </header>
  );
}

export default Header;
