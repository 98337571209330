import React, {useState} from "react";
import {MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight} from "react-icons/md";
import {RxDotFilled} from "react-icons/rx";
import {ImageSet} from "./IImage";

function PictureSlider({imageSet}: {imageSet:ImageSet}) {
  const [currIndex, setCurrIndex] = useState(0);

  function handleClick(inc: number) {
    const newIndex = currIndex+inc;
    if (newIndex === imageSet.images.length) {
      setCurrIndex(0);
    } else if (newIndex === -1) {
      setCurrIndex(imageSet.images.length -1);
    } else {
      setCurrIndex(newIndex);
    }
  }

  function goToIndex(i: number) {
    setCurrIndex(i);
  }

  return(
    <div className="px-14 md:grid md:grid-flow-col md:gap-x-4">
      <div className="max-w-full min-w-40 max-h-[70vh] relative group">
        <div className="max-w-full min-w-40 max-h-[70vh] object-contain transition: opacity 300ms cubic-bezier(.33,0,.2,1)">
          <img className="max-h-[70vh] transition: opacity 300ms cubic-bezier(.33,0,.2,1)"
               src={`/images/${imageSet.images[currIndex].path}`} alt="yee"/>
        </div>
        <div
          className="absolute top-[50%] -translate-x-0 translate-y-[50%] left-5 text-2xl rounded-full p-2 group-hover:bg-black/20 text-white cursor-pointer"
             onClick={() => handleClick(-1)}>
          <MdOutlineKeyboardArrowLeft/>
        </div>
        <div className="absolute top-[50%] -translate-x-0 translate-y-[50%] right-5 text-2xl rounded-full p-2 group-hover:bg-black/20 text-white cursor-pointer"
             onClick={() => handleClick(1)}>
          <MdOutlineKeyboardArrowRight/>
        </div>
        <div className="flex top-4 justify-center py-2">
          {imageSet.images.map((image, index) => (
            <div className={"text-1xl cursor-pointer transition-colors hover:text-black " +
              (index === currIndex ? " text-gray-500" : "text-gray-300")}
                 key={index}
                 onClick={() => goToIndex(index)}>
              <RxDotFilled />
            </div>
          ))}
        </div>
      </div>
      <p className="py-6 text-xl text-gray-800 md:py-0">
        {imageSet.images[currIndex].desc}
      </p>
    </div>
  );
}

export default PictureSlider;
