import {ImageSet} from "./IImage";

const periodDesc = <>Late 19th century pants with zipper fly, side pockets, and top stitching details. Stitched for UBC’s <i>Saint Joan of the Stockyards</i>. Costume design by Madeleine Polak.</>;
const PeriodPants: ImageSet = {
  title: "Period Pants",
  images: [
    {
      path: "costumes/StrawHatPants/StrawhatPantsFront.jpg",
      desc: periodDesc
    },
    {
      path: "costumes/StrawHatPants/StrawhatPantsBack.jpg",
      desc: periodDesc
    },
    {
      path: "costumes/StrawHatPants/StrawhatPantsClose.jpg",
      desc: periodDesc
    },
    {
      path: "costumes/StrawHatPants/StrawhatPantsPatch.jpg",
      desc: periodDesc
    }
  ]
};

const reeseDesc = <>Centre-back and hem alteration, added suspender buttons on dress pants for UBC’s <i>TomorrowLove</i>. Costume design by Colton Albee.</>;
const ReesePants: ImageSet = {
  title: "Dress Pants - Alteration",
  images: [
    {
      path: "costumes/ReesemanPants/ReesemanPants.jpg",
      desc: reeseDesc
    },
    {
      path: "costumes/ReesemanPants/ReesemanPantsCB.jpg",
      desc: reeseDesc
    },
    {
      path: "costumes/ReesemanPants/ReesemanPantsInside.jpg",
      desc: reeseDesc
    }
  ]
};

const promDressDesc = <>Embroidered organza lined with satin. Designed, drafted, and stitched for my graduation.</>;
const PromDress: ImageSet = {
  title: "My Actual Prom Dress",
  images: [
    {
      path: "costumes/PromDress/PromDress.JPG",
      desc: promDressDesc
    },
    {
      path: "costumes/PromDress/PromDressBack.JPG",
      desc: promDressDesc
    }
  ]
};

const embroDesc = <>Simple floral embroidery on a lined zipper pouch.</>;
const EmbroideredPouch: ImageSet = {
  title: "Embroidered Zipper Pouch",
  images: [
    {
      path: "costumes/EmbroideredPouch/EmbroideredPouch.jpg",
      desc: embroDesc
    },
    {
      path: "costumes/EmbroideredPouch/EmbroiderySample.jpg",
      desc: embroDesc
    }
  ]
};

const capePathDesc = <>Sea turtle and dead muskrat made of patchwork embroidery as a part of a cape. Stitched for UBC’s <i>The Birds</i>, costume design by Sophie Fougere.</>;
const CapePatchWorkEmbroidery: ImageSet = {
  title: "Nightingale Cape Patchwork Embroidery",
  images: [
    {
      path: "costumes/CapePatchworkEmbroidery/Nightingale_Cape.jpg",
      desc: capePathDesc
    },
    {
      path: "costumes/CapePatchworkEmbroidery/Dead_Muskrat.jpg",
      desc: capePathDesc
    },
    {
      path: "costumes/CapePatchworkEmbroidery/SeaTurtle.jpg",
      desc: capePathDesc
    }
  ]
};

const ballCapDesc = <>Baseball cap stitched with pattern drafted from existing cap.</>;
const BallCap: ImageSet = {
  title: "Baseball Cap",
  images: [
    {
      path: "costumes/Ballcap/Ballcap.jpg",
      desc: ballCapDesc
    },
    {
      path: "costumes/Ballcap/BallcapLining.jpg",
      desc: ballCapDesc
    },
    {
      path: "costumes/Ballcap/BallcapTop.jpg",
      desc: ballCapDesc
    }
  ]
};

const RegencyVest: ImageSet = {
  title: "Regency Vest",
  images: [
    {
      path: "costumes/RegencyVest.jpg",
      desc: <>Stitched colourful regency vest with welt pockets.</>
    }
  ]
};

const RegencyDress: ImageSet = {
  title: "Regency Dress",
  images: [
    {
      path: "costumes/RegencyDress.jpg",
      desc: <>Regency dress with pocket slits.</>
    }
  ]
};

const MaulerJacket: ImageSet = {
  title: "Jacket Modification",
  images: [
    {
      path: "costumes/MaulerJacket.jpg",
      desc: <>Stiched piping, velvet shoulder wings, and attached with shoulder pads for UBC’s <i>Saint Joan of the Stockyards</i>. Costume design by Madeleine Polak.</>
    }
  ]
};

const HeronCorset: ImageSet = {
  title: "Heron Corset",
  images: [
    {
      path: "costumes/HeronCorset.jpg",
      desc: <>Stitched and decorated boned X-shaped corset for UBC’s <i>The Birds</i>. Costume design by Sophie Foulgere.</>
    }
  ]
};

const ducksweater: ImageSet = {
  title: "Duck Checkered Sweater",
  images: [
    {
      path: "costumes/ducksweater.jpg",
      desc: <>Crocheted one sleeve for UBC’s <i>The Birds</i>. Costume design by Sophie Foulgere, crochet patterning by Celeste Mol.</>
    }
  ]
};

const SewingSampler: ImageSet = {
  title: "Hand Sewing Sample",
  images: [
    {
      path: "costumes/SewingSampler.jpg",
      desc: <>Fastenings, machine button holes, cross stitch and slip stitching.</>
    }
  ]
};

export {PeriodPants, ReesePants, PromDress, EmbroideredPouch, CapePatchWorkEmbroidery, BallCap,
  RegencyVest, RegencyDress, MaulerJacket, HeronCorset, ducksweater, SewingSampler};
