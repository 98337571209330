import React, {useState} from "react";
import {IoCloseSharp} from "react-icons/io5";
import {Image, ImageSet} from "./IImage";
import PictureSlider from "./PictureSlider";
import "./Picture.css";
import EnlargedPic from "./EnlargedPic";

function Single({image, title}: {image:Image, title:string}) {
  return (
    <div className="px-14 md:grid md:grid-flow-col md:gap-x-4">
      <img className="max-w-full min-w-40 max-h-[70vh] object-contain"
           src={`/images/${image.path}`}
           alt={`${title}`}/>
      <p className="text-xl text-gray-800">
        {image.desc}
      </p>
    </div>
  );
}

function Picture({imageSet}: {imageSet: ImageSet}) {
  const [enlarged, setEnlarged] = useState(false);

  function handleClick() {
    setEnlarged(!enlarged);
    document.body.style.overflow =
      enlarged ? "scroll" : "hidden";
  }

  const style: React.CSSProperties = {
    backgroundColor: "4b4b40"
  }
  const overflow: React.CSSProperties = {
    overflow: "hidden"
  }
  return (
    <div id="index-section-NAME"
         className="h-[50vh] md:h-[40vh]">
      <article className="bg-white h-[50vh] relative md:h-[40vh]" style={style}>
        <a className=""
           href={`/${imageSet.images[0].path}`}>
          <figure className="h-full w-full" style={overflow}>
            <img className="h-full w-full object-cover"
              // src="/images/digital art/IMG_3642.JPG"
                 src={`/images/${imageSet.images[0].path}`}
                 alt={`${imageSet.title}`}/>
          </figure>
        </a>
        <div className="cursor-pointer" onClick={handleClick}>
          <div className="justify-center text-center items-center opacity-0 transition: opacity 300ms cubic-bezier(.33,0,.2,1) flex flex-col absolute inset-0 m-auto bg-[#fff3e9] hover:opacity-100 transition duration-300">
            <h2 className="index-item-title">
              <span className="index-item-title-text">
                  {imageSet.title}
              </span>
            </h2>
          </div>
        </div>
        <EnlargedPic enlarged={enlarged} imageSet={imageSet} handleClick={handleClick}/>
      </article>
    </div>
  );
}

export default Picture;
