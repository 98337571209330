import {ImageSet} from "./IImage";

const FabSketches: ImageSet = {
  title: "Fabric Draping Study",
  images: [
    {
      path: "sketches/FabricSketches.JPG",
      desc: <>Digital drawing analyzing how fabric folds and falls on the figure.</>
    }
  ]
};

export {FabSketches}
