import React, {useState} from "react";
import {GrMenu} from "react-icons/gr";
import {NavLink} from "react-router-dom";
import "./Navigation.css";

const hoverBlack = "hover:text-black transition-colors duration-300";

function NavMenu() {
  const [nav, setNav] = useState(false);
  function handleClick() {
    setNav(!nav);
    // disable scrolling when in navmenu
    document.body.style.overflow =
      nav ? "scroll" : "hidden";
  }

  return (
    <div className="flex justify-end items-center md:invisible md:w-0">
      <GrMenu className="z-20 cursor-pointer" size={25} onClick={handleClick} />
      <div className={
        nav
          ? "ease-in duration-300 fixed text-gray-500 left-0 top-0 w-full h-screen bg-white/95 py-7 flex-col z-10"
          : "-z-50 opacity-0 ease-in duration-300 fixed text-gray-500 left-0 top-0 w-full h-screen bg-white/95 py-7 flex-col"
        }
      >
        <ul className="flex flex-col fixed w-full h-full items-center justify-center">
          <li className="font-bold text-3xl p-8">
            <NavLink className={hoverBlack} onClick={handleClick} to="/">Home</NavLink>
          </li>
          <li className="font-bold text-3xl p-8">
            <NavLink className={hoverBlack} onClick={handleClick} to="/contact">Contact</NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

function NavBar() {
  return (
    <div className="invisible w-0 md:w-auto md:visible md:order-3 self-center flex justify-end text-gray-500">
      <nav className="space-x-4">
          <NavLink className={hoverBlack} to="/">Home</NavLink>
          {/*<NavLink className={hoverBlack} to="/about">About</NavLink>*/}
          <NavLink className={hoverBlack} to="/contact">Contact</NavLink>
      </nav>
    </div>
  );
}


export {NavMenu, NavBar}
