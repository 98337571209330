import {ImageSet} from "./IImage";

const AcrylicPigeon: ImageSet = {
  title: "Acrylic Pigeon",
  images: [
    {
      path: "paintings/AcrylicPigeon.jpeg",
      desc: <>Acrylic Pigeon</>
    }
  ]
};

const Amsterdam: ImageSet = {
  title: "Amsterdam",
  images: [
    {
      path: "paintings/Amsterdam.jpg",
      desc: <>Amsterdam</>
    }
  ]
};

const BotanicalBeach: ImageSet = {
  title: "Botanical Beach",
  images: [
    {
      path: "paintings/BotanicalBeach.jpg",
      desc: <>Botanical Beach</>
    }
  ]
};

const JoffreLakes1: ImageSet = {
  title: "Joffre Lakes #1",
  images: [
    {
      path: "paintings/JoffreLakes1.JPG",
      desc: <>Joffre Lakes #1</>
    }
  ]
};

const JoffreLakes2: ImageSet = {
  title: "Joffre Lakes #2",
  images: [
    {
      path: "paintings/JoffreLakes2.JPG",
      desc: <>Joffre Lakes #2</>
    }
  ]
};

const JoffreLakes3: ImageSet = {
  title: "Joffre Lakes #3",
  images: [
    {
      path: "paintings/JoffreLakes3.JPG",
      desc: <>Joffre Lakes #3</>
    }
  ]
};

const PortRenfrew: ImageSet = {
  title: "Port Renfrew",
  images: [
    {
      path: "paintings/PortRenfrew.jpg",
      desc: <>Port Renfrew</>
    }
  ]
};

const Quebec: ImageSet = {
  title: "Quebec",
  images: [
    {
      path: "paintings/Quebec.JPG",
      desc: <>Quebec</>
    }
  ]
};

const Whistler1: ImageSet = {
  title: "Whistler #1",
  images: [
    {
      path: "paintings/Whistler1.jpg",
      desc: <>Whistler #1</>
    }
  ]
};

const Whistler2: ImageSet = {
  title: "Whistler #2",
  images: [
    {
      path: "paintings/Whistler2.jpg",
      desc: <>Whistler #2</>
    }
  ]
};

const Whistler3: ImageSet = {
  title: "Whistler #3",
  images: [
    {
      path: "paintings/Whistler3.jpg",
      desc: <>Whistler #3</>
    }
  ]
};

export {AcrylicPigeon, Amsterdam, BotanicalBeach, JoffreLakes1, JoffreLakes2, JoffreLakes3,
        PortRenfrew, Quebec, Whistler1, Whistler2, Whistler3};
