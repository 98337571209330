import {ImageSet} from "./IImage";

const Worbla: ImageSet = {
  title: "Worbla Cast Decoration",
  images: [
    {
      path: "props/WorblaCornerThing/WorblaCornerDecoration.jpg",
      desc: <>Decoration sculpted in plasticine, molded and cast into worbla.</>
    },
    {
      path: "props/WorblaCornerThing/WorblaCornerDecorationTurnedFan.jpg",
      desc: <>Fans from UBC’s <i>Parliament of the Birds</i>.</>
    }
  ]
}

const RavenSkullMask: ImageSet = {
  title: "Raven Skull Mask",
  images: [
    {
      path: "props/RavenSkullMask/RavenSkullMask.jpg",
      desc: <>Painted paper maché mask of a raven skull.</>
    },
    {
      path: "props/RavenSkullMask/Clay_Raven_Skull.jpg",
      desc: <>Original clay sculpt of the mask.</>
    }
  ]
};

const Scepter: ImageSet = {
  title: "Scepter",
  images: [
    {
      path: "props/Scepter.jpg",
      desc: <>Wooden and plastic scepter with LED lights illuminating the orb.</>
    }
  ]
};

const PokemonGift: ImageSet = {
  title: "Pokémon Inspired Gift Box",
  images: [
    {
      path: "props/PokemonGiftBox.jpg",
      desc: <>Cardstock functional gift box. A recreation of the gift item in Pokémon Go.</>
    }
  ]
};

const MarineTableRunner: ImageSet = {
  title: "Marine Inspired Table Runner",
  images: [
    {
      path: "props/MarineTableRunner.jpg",
      desc: <>Patchwork marine table runner designed for UBC’s <i>TomorrowLove</i>. Scenic design by Ana Camacho and Taylor Wen.</>
    }
  ]
};

const ThroneBack: ImageSet = {
  title: "Throne Back",
  images: [
    {
      path: "props/ThroneBack.jpg",
      desc: <>Painted cardboard throne back of the metaphorical kingdom which follows the king. Made for UBC’s 2023 production of <i>Commedia dell’Arte</i>.</>
    }
  ]
};

const Cake: ImageSet = {
  title: "Cake Gift Box",
  images: [
    {
      path: "props/Cake.jpg",
      desc: <>Cardboard “cake” box with tissue paper frosting.</>
    }
  ]
}

// TODO
const OrangeMov: ImageSet = {
  title: "Orange Transformation Dress",
  images: [
    {
      path: "",
      desc: <></>
    }
  ]
}
/*
“The .mov file”
Orange Transformation Dress
Orange costume which blooms into a dress skirt. Made and designed collaboratively in the UBC Prop Shop for UBC’s 2023 production of Commedia dell’Arte.
 */

export {Worbla, RavenSkullMask, PokemonGift, MarineTableRunner, ThroneBack, Cake, Scepter}
