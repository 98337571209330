import {ImageSet} from "./IImage";

const dinoDesc = <>Crocheted plush dinosaur without a pattern.</>;
const CrochetDino: ImageSet = {
  title: "Crochet Dinosaur",
  images: [
    {
      path: "misc/CrochetDino/CrochetDinoFront.jpg",
      desc: dinoDesc
    },
    {
      path: "misc/CrochetDino/CrochetDinoSide.jpg",
      desc: dinoDesc
    }
  ]
};

const ShrinkPlasticCharms: ImageSet = {
  title: "Hand-Drawn Keychains",
  images: [
    {
      path: "misc/ShrinkPlasticCharms.jpg",
      desc: <>Pencil crayons on shrink plastic.</>
    }
  ]
};

const RealCake: ImageSet = {
  title: "Piped Cake",
  images: [
    {
      path: "misc/RealCake.jpg",
      desc: <>Lily pond cake with colourful buttercream.</>
    }
  ]
}

const Gazelle: ImageSet = {
  title: "Clay Gazelle Mask",
  images: [
    {
      path: "misc/Gazelle.jpg",
      desc: <>Sculpted from natural clay and painted with acrylic.</>
    }
  ]
};

const Earrings: ImageSet = {
  title: "Wire Clip-On Earrings",
  images: [
    {
      path: "misc/Earrings.jpg",
      desc: <>Wire earrings made with mechanism designed for non-pierced ears.</>
    }
  ]
};

const CrochetPurse: ImageSet = {
  title: "Crochet Purse",
  images: [
    {
      path: "misc/CrochetPurse.JPG",
      desc: <>Purse made with a pattern, with removable decorations.</>
    }
  ]
};

const Cookie: ImageSet = {
  title: "Felt Linzer Cookie",
  images: [
    {
      path: "misc/Cookie.jpg",
      desc: <>Hand-stitched felt stuffed cookie.</>
    }
  ]
};

export {CrochetDino, ShrinkPlasticCharms, RealCake, Gazelle, Earrings, CrochetPurse, Cookie};
