import {ImageSet} from "./IImage";

const Truck: ImageSet = {
  title: "Truck",
  images: [
    {
      path: "digitalArt/Truck.JPG",
      desc: <>Digital painting from a personal photo.</>
    }
  ]
};

const Pigeon: ImageSet = {
  title: "Pigeon",
  images: [
    {
      path: "digitalArt/Pigeon.JPG",
      desc: <>Digital painting from a personal photo.</>
    }
  ]
};

const PetIllist: ImageSet = {
  title: "Pet Illustrations",
  images: [
    {
      path: "digitalArt/PetIllustrations.JPG",
      desc: <>Stylized illustration from photos.</>
    }
  ]
};

const Glue: ImageSet = {
  title: "Glue",
  images: [
    {
      path: "digitalArt/Glue.JPG",
      desc: <>Digital painting from life.</>
    }
  ]
};

const Evening: ImageSet = {
  title: "Fireflies",
  images: [
    {
      path: "digitalArt/Evening.JPG",
      desc: <>Stylized creative illustration.</>
    }
  ]
};

export {Truck, Pigeon, PetIllist, Glue, Evening};
