import React from "react";
import Header from "../components/Header";
import {MdOutlineMail} from "react-icons/md";
import {BiLogoGmail} from "react-icons/bi";
import {NavLink} from "react-router-dom";

const gmail: string = "msaito2004@gmail.com";
const ubcEmail: string = "misaito@student.ubc.ca";

function Contact() {
  document.title = "Contact - Marília Saito";
  return (
    <div className="overflow-wrapper">
      <div id="site" className="site-container">
        <Header/>
        <Content/>
      </div>
    </div>
  );
}

function Content() {
  return (
    <div id="content" className="content-container mt-7 ml-14 mr-14">
      <main id="page" className="" role="main">
        <div className="main-content grid justify-items-center grid-cols-1 md:grid-cols-3 md:gap-6 md:justify-items-center">
          <img className="max-w-72 md:min md:max-w-64 md:w-[100%]" src="/images/digitalArt/Pigeon.JPG" alt="Profile"/>
          <p className="py-8 col-span-2 md:py-0">
            <h1 className="font-semibold text-2xl">
              Marília Saito
            </h1>
            <p className="font-thin mt-2 mb-1">CONTACT</p>
            <div id="emais" className="hover:text-gray-500 transition-colors duration-300">
              <NavLink to={`mailto:${gmail}`} className="flex justify-start hover:text-black">
                <BiLogoGmail className="size-6"/>
                <>&nbsp;{gmail}</>
              </NavLink>
              <NavLink to={`mailto:${ubcEmail}`} className="flex justify-start hover:text-black">
                <MdOutlineMail className="size-6"/>
                <>&nbsp;{ubcEmail}</>
              </NavLink>
            </div>
          </p>
        </div>
      </main>
    </div>
  );
}

export default Contact;
