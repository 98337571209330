import {Image, ImageSet} from "./IImage";
import React, {MouseEventHandler} from "react";
import {IoCloseSharp} from "react-icons/io5";
import PictureSlider from "./PictureSlider";

function Enlarged({enlarged, imageSet, handleClick}:
                    {enlarged:boolean, imageSet:ImageSet, handleClick:MouseEventHandler<SVGElement>}) {

  // return (
  //   <div className={
  //     enlarged
  //       ? "ease-in duration-300 fixed text-gray-500 left-0 top-0 w-full h-screen bg-white/95 py-7 flex-col z-30"
  //       : "-z-50 opacity-0 ease-in duration-300 fixed text-gray-500 left-0 top-0 w-full h-screen bg-white/95 py-7 flex-col"
  //   }
  //   >
  //     <div className="flex justify-between px-14 py-4">
  //       <span className="text-2xl font-semibold">{imageSet.title}</span>
  //       <IoCloseSharp className="size-8 hover:text-black cursor-pointer" onClick={handleClick}/>
  //     </div>
  //     {imageSet.images.length > 1
  //       ? (<PictureSlider imageSet={imageSet}/>)
  //       : (<Single image={imageSet.images[0]} title={imageSet.title}/>)}
  //   </div>
  // );

  return (
    <div className={
      enlarged
        ? "ease-in duration-300 fixed text-gray-500 left-0 top-0 w-full h-screen bg-white/95 py-7 flex-col z-30"
        : "-z-50 opacity-0 ease-in duration-300 fixed text-gray-500 left-0 top-0 w-full h-screen bg-white/95 py-7 flex-col"
    }
    >
      <div className="flex justify-between px-14 py-4">
        <span className="text-2xl font-semibold">{imageSet.title}</span>
        <IoCloseSharp className="size-8 hover:text-black cursor-pointer" onClick={handleClick}/>
      </div>
      {enlarged
        ? (<Thing imageSet={imageSet}/>)
        : (<>invisible</>)}
    </div>
  );
}

function Thing({imageSet}: {imageSet:ImageSet}) {
  return (
    imageSet.images.length > 1
      ? (<PictureSlider imageSet={imageSet}/>)
      : (<Single image={imageSet.images[0]} title={imageSet.title}/>)
  );
}

function Single({image, title}: { image: Image, title: string }) {
  return (
    <div className="px-14 md:grid md:grid-flow-col md:gap-x-4">
      <img className="max-w-full min-w-40 max-h-[70vh] object-contain"
           src={`/images/${image.path}`}
           alt={`${title}`}/>
      <p className="text-xl text-gray-800">
        {image.desc}
      </p>
    </div>
  );
}

export default Enlarged;
