import React from "react";
import Header from "../components/Header";
import Picture from "../components/Picture";
import {Cake, MarineTableRunner, PokemonGift, RavenSkullMask, Scepter, ThroneBack, Worbla} from "../components/Props";
import {
  BallCap,
  CapePatchWorkEmbroidery, ducksweater,
  EmbroideredPouch, HeronCorset, MaulerJacket,
  PeriodPants,
  PromDress,
  ReesePants, RegencyDress, RegencyVest
} from "../components/Costumes";
import {
  AcrylicPigeon,
  Amsterdam,
  BotanicalBeach,
  JoffreLakes1,
  JoffreLakes2,
  JoffreLakes3, PortRenfrew, Quebec, Whistler1, Whistler2, Whistler3
} from "../components/Paintings";
import {Evening, Glue, PetIllist, Pigeon, Truck} from "../components/DigitalArt";
import {FabSketches} from "../components/Sketches";
import {Cookie, CrochetDino, CrochetPurse, Earrings, Gazelle, RealCake, ShrinkPlasticCharms} from "../components/Misc";

function Home() {
  document.title = "Marília Saito";
  return (
    <div className="overflow-wrapper">
      <div id="site" className="site-container">
        <Header/>
        <Content/>
      </div>
    </div>
  );
}

function Content() {
  return (
    <div id="content" className="content-container mt-7 ml-14 mr-14">
      <main id="page" className="" role="main">
        <div className="main-content">
          <div className="index-list space-y-5 md:grid md:grid-cols-2 md:space-y-0 md:gap-5 md:justify-stretch lg:grid-cols-3 lg:h-[40vh]">
            <Picture imageSet={Worbla}/>
            <Picture imageSet={RavenSkullMask}/>
            <Picture imageSet={PokemonGift}/>
            <Picture imageSet={MarineTableRunner}/>
            <Picture imageSet={ThroneBack}/>
            <Picture imageSet={Cake}/>
            <Picture imageSet={Scepter}/>

            <Picture imageSet={PeriodPants}/>
            <Picture imageSet={ReesePants}/>
            <Picture imageSet={PromDress}/>
            <Picture imageSet={EmbroideredPouch}/>
            <Picture imageSet={CapePatchWorkEmbroidery}/>
            <Picture imageSet={BallCap}/>
            <Picture imageSet={RegencyVest}/>
            <Picture imageSet={RegencyDress}/>
            <Picture imageSet={MaulerJacket}/>
            <Picture imageSet={HeronCorset}/>
            <Picture imageSet={ducksweater}/>

            <Picture imageSet={AcrylicPigeon}/>
            <Picture imageSet={Amsterdam}/>
            <Picture imageSet={BotanicalBeach}/>
            <Picture imageSet={JoffreLakes1}/>
            <Picture imageSet={JoffreLakes2}/>
            <Picture imageSet={JoffreLakes3}/>
            <Picture imageSet={PortRenfrew}/>
            <Picture imageSet={Quebec}/>
            <Picture imageSet={Whistler1}/>
            <Picture imageSet={Whistler2}/>
            <Picture imageSet={Whistler3}/>

            <Picture imageSet={Truck}/>
            <Picture imageSet={Pigeon}/>
            <Picture imageSet={PetIllist}/>
            <Picture imageSet={Glue}/>
            <Picture imageSet={Evening}/>

            <Picture imageSet={FabSketches}/>

            <Picture imageSet={CrochetDino}/>
            <Picture imageSet={ShrinkPlasticCharms}/>
            <Picture imageSet={RealCake}/>
            <Picture imageSet={Gazelle}/>
            <Picture imageSet={Earrings}/>
            <Picture imageSet={CrochetPurse}/>
            <Picture imageSet={Cookie}/>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Home;
